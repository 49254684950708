import $ from 'jquery';

import ManageAccountView from './ManageAccountView';

export class EditGroupView extends ManageAccountView {
    constructor(modalView, accountManager, chat) {
        super(modalView, accountManager);
        this.chat = chat;
    }
    
    populatePage() {
        var self = this;
        $("#profileImage").attr("src", this.chat.getChatPictureUrl());
    
        $("#usernameInput").val(this.chat.name);
        $("#usernameInput").keyup(function() {
            if($("#usernameInput").val() !== self.chat.name) {
                self.enableSaveButton();
            } else if(!this.bImageChanged) {
                self.disableSaveButton();
            }
        }); 

        this.setButtons();
        this.setParticipants();
    }

    setParticipants() {
        $("#participantsDiv").html("");
        var arrayLength = this.chat.activeParticipants.length;
        for(var i = 0; i < arrayLength; i++) {
            var contact = this.chat.activeParticipants[i];
            $("#participantsDiv").append(this.getHTMLForContact(contact));
        }
    }

    getHTMLForContact(contact) {
        var htmlContent = '<div class="contact-row")">';
        htmlContent += '<div class="left-div"> <img class="pin-image" src="' + contact.photoUrl + '" /> </div>';
        htmlContent += '<div class="middle-div">';
        htmlContent += '<div class="ihunter-menu-text medium top">'+ contact.username + '</div>';
        htmlContent += '<div class="bottom-div">';
        htmlContent += '<div class="ihunter-menu-text small left">' + contact.email + '</div>';
        htmlContent += '</div>';
        htmlContent += '</div>';
        htmlContent += '</div>';
        return htmlContent;
    }
    

    saveAndClose() {
        if($("#usernameInput").val() !== this.chat.name && $("#usernameInput").val().trim() !== "") {
            console.log("Changing group name");
            //gFirebaseHelper.setGroupChatName($("#usernameInput").val().trim(), this.chat.chatkey);
            let name = $("#usernameInput").val().trim();
            this.chatManager.updateGroupChatName(this.chat.chatkey, name);
        }
        if(this.bImageChanged && this.pendingImage) {
            console.log("Changing group profile picture");
            this.chatManager.updateProfileImage(this.chat.chatkey, this.pendingImage);
            //gFirebaseHelper.pushProfileImageToStorage(this.pendingImage, this.chat.chatkey, true);
        }
        if(this.bImageChanged && this.profileImageSource == "images/noProfilePic.jpg") {
            this.chatManager.deleteProfileImage(this.chat.chatkey);
            //gFirebaseHelper.deleteProfileImageInStorage(this.chat.chatkey, true);
        }
        
    }
}

export default EditGroupView;
