import $ from 'jquery';

import View from './View';

export class CreateGroupView extends View {
    constructor(modalView, chatManager) {
        super();
        var self = this;
        this.chatManager = chatManager;
        this.selectedContacts = new Array();
        this.contacts = new Array();
        this.modal = modalView;
        
        this.modal.find('#modalPrimaryButton').attr("disabled", true); //disable save button to begin with.
    
        this.modal.find('#modalPrimaryButton').off(); //removes previous onclick
        this.modal.find('#modalPrimaryButton').on('click', 
            function(event) {
                self.saveAndClose();
            }
        );

        this.populateContacts();
        this.appendContactsToHTML();
    }


    saveAndClose() {
        this.selectedContacts.push(this.chatManager.getUserAsContact());
        this.chatManager.createChatForContacts(this.selectedContacts);
    }

    populateContacts() {
        this.contacts = this.chatManager.contactManager.getContactsArray();
    }

    appendContactsToHTML() {
        $("#availableContacts").html("");
        var self = this;
        if(this.contacts && this.contacts.length > 0) {
            this.sortContactsArray(this.contacts);
            for (const key in this.contacts) {
                var contact = this.contacts[key];
                var html = this.getHTMLForAddContactRow(contact, this.selectedContactForUID(contact.uid) != null);
                $("#availableContacts").append(html);

                $("#"+contact.uid+"_createGroup").click(function() {
                    var id = $(this).attr('id');
                    var uid = id.substring(0, id.length - 12);
                    self.selectContactClicked(uid);
                });
            }
        }
    }

    selectedContactForUID(uid) {
        var arrayLength = this.selectedContacts.length;
        for(var i = 0; i < arrayLength; i++) {
            var contact = this.selectedContacts[i];
            if(contact.uid === uid) {
                return contact;
            }
        }
        return null;
    }

    sortContactsArray(array) {
        array.sort(function(a, b){
            var aName = a.username;
            var bName = b.username;
            if(aName < bName) {return 1;}
            if(aName > bName) {return -1;}
            return 0;
        });
    }

    getHTMLForAddContactRow(contact, bShowCheck) {
        var leftImageSrc = 'images/noProfilePic.jpg';
        var title = contact.username;
        var subTitle = contact.email;
    
        // var htmlContent = '<div class="contact-row" id="'+contact.uid+"_addContact"+'" onClick="selectContactClicked(\''+contact.uid+'\')">';
        var htmlContent = '<div class="contact-row" id="'+contact.uid+'_createGroup'+'">';
        
            htmlContent += '<div class="left-div"> <img class="pin-image" src="' + leftImageSrc + '" /> </div>';
            htmlContent += bShowCheck ? '<div class="middle-div includes-right-div">' : '<div class="middle-div">';
                htmlContent += '<div class="ihunter-menu-text medium top">'+ title + '</div>';
                htmlContent += '<div class="bottom-div">';
                htmlContent += '<div class="ihunter-menu-text small left">' + subTitle + '</div>';
                htmlContent += '</div>';
            htmlContent += '</div>';
            if(bShowCheck) {
                htmlContent += '<div class="right"> <img class="pin-image" src="images/checkmark.png" /> </div>';
            }
    
        htmlContent += '</div>';
        
        return htmlContent;
    }

    selectContactClicked(uid) {
        // console.log("Clicked: " + uid);
        for(let i = 0; i < this.selectedContacts.length; i++) {
          let contact = this.selectedContacts[i];
            if(contact.uid === uid) {
                this.selectedContacts.splice(i, 1);
                this.appendContactsToHTML();
                this.checkSaveEnabled();
                return; 
            }
        }
        for(let i = 0; i < this.contacts.length; i++) {
          let contact = this.contacts[i];
            if(contact.uid === uid) {
                this.selectedContacts.push(contact);
                this.appendContactsToHTML();
                this.checkSaveEnabled();
                return; 
            }
        }
    }

    checkSaveEnabled() {
        if(this.selectedContacts && this.selectedContacts.length >= 2) {
            $('#modalPrimaryButton').removeAttr("disabled"); //enable save button.
        } else {
            $('#modalPrimaryButton').attr("disabled", true); //disable save button.
        }
    }
}

export default CreateGroupView;
