import DateHelpers from './DateHelpers';

export class WeatherInfo {
    constructor(jsonString) {
        this.json = jsonString;
        var jsonObj = JSON.parse(jsonString);

        this.requestDate = Object.prototype.hasOwnProperty.call(jsonObj, "request_time") ? DateHelpers.dateFromTimeInSeconds(parseFloat(jsonObj.request_time)) : -1;
        this.latitude = Object.prototype.hasOwnProperty.call(jsonObj, "lat") ? parseFloat(jsonObj.lat) : -1;
        this.longitude = Object.prototype.hasOwnProperty.call(jsonObj, "lon") ? parseFloat(jsonObj.lon) : -1;

        this.weatherDate = Object.prototype.hasOwnProperty.call(jsonObj, "weather_time") ? DateHelpers.dateFromTimeInSeconds(parseFloat(jsonObj.weather_time)) : null;
        this.windSpeedKiloPerHour = Object.prototype.hasOwnProperty.call(jsonObj, "wind_speed") ? parseFloat(jsonObj.wind_speed) * 3.6 : null;
        this.windDegrees = Object.prototype.hasOwnProperty.call(jsonObj, "wind_dir") ? parseInt(jsonObj.wind_dir) : null;
        this.windDirection = this.getWindDirectionDescription(this.windDegrees);
        this.temperature = Object.prototype.hasOwnProperty.call(jsonObj, "temp") ? Math.round(parseFloat(jsonObj.temp)) : null;
        this.feelsLikeTemperature = Object.prototype.hasOwnProperty.call(jsonObj, "feels_like") ? Math.round(parseFloat(jsonObj.feels_like)) : null;
        this.pressure = Object.prototype.hasOwnProperty.call(jsonObj, "pressure") ? parseInt(jsonObj.pressure) : null;
        this.humidty = Object.prototype.hasOwnProperty.call(jsonObj, "humidity") ? jsonObj.humidity : "";
        this.clouds = Object.prototype.hasOwnProperty.call(jsonObj, "clouds") ? jsonObj.clouds : "";
        this.rainMillimeters1h = Object.prototype.hasOwnProperty.call(jsonObj, "rain_1h") ? parseFloat(jsonObj.rain_1h) : null;
        this.rainMillimeters3h = Object.prototype.hasOwnProperty.call(jsonObj, "rain_3h") ? parseFloat(jsonObj.rain_3h) : null;
        this.snowMillimeters1h = Object.prototype.hasOwnProperty.call(jsonObj, "snow_1h") ? parseFloat(jsonObj.snow_1h) : null;
        this.snowMillimeters3h = Object.prototype.hasOwnProperty.call(jsonObj, "snow_3h") ? parseFloat(jsonObj.snow_3h) : null;
        this.weatherID = Object.prototype.hasOwnProperty.call(jsonObj, "weather_id") ? jsonObj.weather_id : "";
        this.weatherMain = Object.prototype.hasOwnProperty.call(jsonObj, "weather_main") ? jsonObj.weather_main : "";
        this.weatherIconCode = Object.prototype.hasOwnProperty.call(jsonObj, "weather_icon") ? jsonObj.weather_icon : "";
        if(this.weatherIconCode != "") {
            this.weatherImageUrl = "images/openweather/" + this.weatherIconCode + "@2x.png";
        }
        this.weatherDescription = Object.prototype.hasOwnProperty.call(jsonObj, "weather_desc") ? jsonObj.weather_desc : "";
    }

    getWindDirectionDescription(degrees) {
        if(degrees == null) {
            return "Dir. Unknown"
        }
        if (degrees>337.5) return "N";
        if (degrees>292.5) return "NW";
        if (degrees>247.5) return "W";
        if (degrees>302.5) return "SW";
        if (degrees>157.5) return "S";
        if (degrees>122.5) return "SE";
        if (degrees>67.5) return "E";
        if (degrees>22.5) return "NE";
        return "N";
    }

}

export default WeatherInfo;
